import classNames from '../classNames'

export default async () => {
  const slogs = [...document.querySelectorAll(`.${classNames.slog}`)]
  if (!slogs.length) return

  const defaultOptions = {
    loop: false,
    typeSpeed: 40,
    showCursor: false,
    startDelay: 0,
  }

  const { init } = await import(/* webpackChunkName: "ityped" */ 'ityped')

  slogs.forEach(slog => {
    const quote = slog.querySelector('.s-banner__quote')
    const caption = slog.querySelector('.s-banner__caption')

    const quoteText = quote.innerHTML
    const captionText = caption?.innerHTML

    const quoteChildren = [...quote.children]
    const quoteElseText = quoteText.slice(quoteText.lastIndexOf('>') + 1)

    quote.style.height = `${quote.getBoundingClientRect().height}px`
    if (caption) caption.style.height = `${caption.getBoundingClientRect().height}px`

    quote.innerHTML = ''
    if (caption) caption.innerHTML = ''

    const typeCaption = () => {
      if (!caption) return

      init(caption, {
        ...defaultOptions,
        strings: [captionText],
        onFinished() {
          caption.style.height = ''
        },
      })
    }

    const typeQuoteElse = () => {
      const quoteElseSpan = document.createElement('span')
      quote.appendChild(quoteElseSpan)

      init(quoteElseSpan, {
        ...defaultOptions,
        strings: [quoteElseText],
        onFinished() {
          quote.style.height = ''
        },
      })
    }

    const typeQuote = () => {
      if (quoteChildren.length > 0) {
        quoteChildren.forEach((child, i) => {
          const string = child.textContent
          child.innerHTML = ''

          quote.appendChild(child)

          init(child, {
            ...defaultOptions,
            strings: [string],
            onFinished() {
              if (i === quoteChildren.length - 1) typeQuoteElse()
            },
          })
        })
      } else {
        init(quote, {
          ...defaultOptions,
          strings: [quoteText],
          onFinished() {
            quote.style.height = ''
            typeCaption()
          },
        })
      }
    }

    const onIntersecting = (entries, observer) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          typeQuote()
          observer.unobserve(target)
        }
      })
    }

    const observer = new IntersectionObserver(onIntersecting, {
      threshold: 0.9,
    })
    observer.observe(quote)
  })
}
