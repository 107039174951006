import { DELAYS, IS_READY } from '../constants'

export default () => {
  let preloader = document.getElementById('preloader')
  document.documentElement.style.overflow = 'hidden'

  const removePreloader = () => {
    const fadeOutDuration = 750

    preloader.style.transition = `opacity ${fadeOutDuration}ms`
    preloader.style.opacity = 0

    setTimeout(() => {
      if (!preloader) return
      preloader.parentNode.removeChild(preloader)
      preloader = null
      document.documentElement.style.overflow = ''
    }, fadeOutDuration)
  }

  const heroImages = [...document.querySelectorAll('.hero__bg img')]
  const heroIsNotInview = window.scrollY >= window.innerHeight

  let isVisible = []
  let isProcessed = []
  let iterations = 0

  const interval = setInterval(() => {
    iterations += 1

    // TODO: Add preloader handler, if there is no hero images on new pages.
    if (
      (heroIsNotInview || !heroImages.length) &&
      document.documentElement.classList.contains(IS_READY)
    ) {
      removePreloader()
      clearInterval(interval)
    }

    if (!isVisible.length && document.documentElement.classList.contains(IS_READY)) {
      isVisible = heroImages.filter(img => window.getComputedStyle(img).display !== 'none')
    }

    isProcessed = isVisible.filter(({ dataset }) => dataset.processed)

    if (isVisible.length > 0 && isVisible.length === isProcessed.length) {
      removePreloader()
      clearInterval(interval)
    }
    if (iterations >= 200 && preloader) {
      removePreloader()
      clearInterval(interval)
    }
  }, DELAYS.medium)
}
